<template>
    <v-card class="elevation-8 mt-4" style="width: 80%; margin: 0 auto;">
        <v-card-title>
            <v-row justify="center" class="my-4">
                <v-col cols="12" class="text-center">
                    <img src="@/assets/images/logos/chess-hotel.png" alt="CHESS HOTEL" class="me-3"
                        style="max-width: 400px;">
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-subtitle>
            <h1 class="text--primary text-center">
                Formulário de cadastro
            </h1>
        </v-card-subtitle>
        <v-card-text v-if="valid_reserve">
            <v-row no-gutters class="mt-5">
                <v-col cols="8">
                    <v-text-field v-model="name" outlined label="Nome" :hide-details="true"
                        :error="error_message.name !== ''" :error-messages="error_message.name"
                        @input="error_message.name = ''"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field class="ml-2" v-mask="'###.###.###-##'" v-model="guest.cpf" outlined label="CPF"
                        :hide-details="true" :error="error_message.cpf !== ''" :error-messages="error_message.cpf"
                        @input="validateUser"></v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters class="mt-2">
                <v-col class="">
                    <v-text-field v-mask="'##.###-###'" v-model="guest.zipcode" outlined hide-details label="CEP"
                        @input="getCEP" :error="error_message.zipcode !== ''"
                        :error-messages="error_message.zipcode"></v-text-field>
                </v-col>
                <v-col cols="6" class="ml-2">
                    <v-select v-model="guest.country_id" :items="country_items" item-value="id" item-text="name"
                        outlined hide-details label="País" :error="error_message.country_id !== ''"
                        :error-messages="error_message.country_id" @input="error_message.country_id = ''"></v-select>
                </v-col>
            </v-row>
            <v-row no-gutters class="mt-2">
                <v-col>
                    <v-text-field v-model="guest.address" outlined hide-details label="Endereço"
                        :error="error_message.address !== ''" :error-messages="error_message.address"
                        @input="error_message.address = ''"></v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters class="mt-2">
                <v-col cols="3">
                    <v-text-field v-model="guest.number" outlined hide-details label="Número"
                        :error="error_message.number !== ''" :error-messages="error_message.number"
                        @input="error_message.number = ''"></v-text-field>
                </v-col>
                <v-col class="ml-2">
                    <v-text-field v-model="guest.complement" outlined hide-details label="Complemento"></v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters class="mt-2">
                <v-col cols="12">
                    <v-text-field v-model="guest.neighborhood" outlined hide-details label="Bairro"
                        :error="error_message.neighborhood !== ''" :error-messages="error_message.neighborhood"
                        @input="error_message.neighborhood = ''"></v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters class="mt-2">
                <v-col cols="">
                    <v-select @change="loadCities()" v-model="guest.state_id" :items="state_items" item-value="id"
                        item-text="name" outlined hide-details label="UF" :error="error_message.state_id !== ''"
                        :error-messages="error_message.state_id" @input="error_message.state_id = ''"></v-select>
                </v-col>
                <v-col cols="" class="ml-2">
                    <v-select v-model="guest.city_id" :items="city_items" item-value="id" item-text="name" outlined
                        hide-details label="Cidade" :error="error_message.city_id !== ''"
                        :error-messages="error_message.city_id" @input="error_message.city_id = ''"></v-select>
                </v-col>
                <!-- <v-col class="ml-2">
                                                    <v-text-field v-model="guest.ibge_code" outlined hide-details label="Cod. IBGE"></v-text-field>
                                                </v-col> -->
            </v-row>
            <v-row no-gutters class="mt-2">
                <v-col cols="3">
                    <v-text-field v-mask="'(##)####-####'" v-model="guest.home_phone" outlined hide-details
                        label="Fone Res."></v-text-field>
                </v-col>
                <v-col cols="3" class="ml-2">
                    <v-text-field v-mask="'(##)####-####'" v-model="guest.commercial_phone" outlined hide-details
                        label="Fone Com."></v-text-field>
                </v-col>
                <v-col cols="3" class="ml-2">
                    <v-text-field v-mask="'(##)#####-####'" v-model="guest.cell_phone" outlined hide-details
                        label="Celular"></v-text-field>
                </v-col>
                <v-col class="ml-2">
                    <v-text-field v-mask="'(##)####-####'" v-model="guest.fax_phone" outlined hide-details
                        label="Fax"></v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters class="mt-2">
                <v-col>
                    <v-text-field v-model="guest.inf_commercial" outlined hide-details
                        label="inf. Comerciais"></v-text-field>
                </v-col>

            </v-row>
            <v-row no-gutters class="mt-2">
                <v-col>
                    <v-text-field v-model="email" outlined hide-details label="Email"
                        :error="error_message.email !== ''" :error-messages="error_message.email"></v-text-field>
                </v-col>
            </v-row>

        </v-card-text>
        <v-card-text v-else>
            <v-form @submit.prevent="validateReserve">
                <v-row class="mt-5">
                    <v-col>
                        <h3 class="text-center">
                            Para continuar, insira o número da reserva.
                        </h3>
                    </v-col>
                </v-row>

                <v-row no-gutters class="mt-2 justify-center align-center">
                    <v-col cols="6" class="text-center">
                        <v-text-field v-model="reserve" outlined hide-details label="Número da Reserva"
                            :error="error_message.reserve !== ''" :error-messages="error_message.reserve"
                            @input="error_message.reserve = ''">
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row no-gutters class="mt-2 justify-center align-center">
                    <v-col cols="4" class="text-center">
                        <v-btn :loading="loading" x-large color="primary" @click="validateReserve">
                            Continuar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>

        <v-card-actions v-if="valid_reserve">
            <v-row>
                <v-col class="text-center">
                    <v-btn :loading="loading" x-large color="primary" @click="save">
                        Registar
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>

<script>


import { mapActions, mapMutations } from 'vuex'
import axios from 'axios'


export default {
    components: {


    },
    props: {

    },
    data: () => ({
        loading: false,
        country_items: [],
        state_items: [],
        city_items: [],
        reserve: '',
        valid_reserve: false,

        error_message: {
            name: '',
            cpf: '',
            address: '',
            number: '',
            neighborhood: '',
            country_id: '',
            zipcode: '',
            state_id: '',
            city_id: '',
            email: '',
            reserve: '',
        },

        guest: {
            cpf: '',
            name: '',
            address: '',
            number: '',
            neighborhood: '',
            country_id: '',
            zipcode: '',
            state_id: '',
            city_id: '',
            email: '',
        },

    }),
    watch: {

    },

    created() {
        this.inicialize()
    },

    computed: {
        name: {
            get() {
                return this.guest.name
            },
            set(val) {
                //letra maiusculas no inicio da palavra
                const vals = val.split(' ')
                val = vals
                    .map(item => {
                        if (item[0]) {
                            return item[0].toUpperCase() + item.substring(1).toLowerCase()
                        }
                    })
                    .join(' ')

                this.guest.name = val
            },
        },
        email: {
            get() {
                return this.guest.email
            },
            set(val) {
                this.guest.email = val.toLowerCase()
            },
        },

    },

    methods: {
        ...mapActions('noauth', ['isRegistered', 'get_countries', 'get_states', 'get_cities', 'register_guest', 'validate_reserve']),

        inicialize() {
            this.get_countries().then(response => {
                this.country_items = response.data

            })

            this.get_states().then(response => {
                this.state_items = response.data
            })

        },
        loadCities() {
            const state = this.state_items.find(state => state.id == this.guest.state_id) // pega o objeto estado
            this.get_cities(state.code).then(response => {
                this.city_items = response.data
            })
        },
        loadCitiesViaCep(localidade) {
            const state = this.state_items.find(state => state.id == this.guest.state_id) // pega o objeto estado
            this.get_cities(state.code).then(response => {
                this.city_items = response.data
                let id_city = this.city_items.find(city => city.name == localidade)
                this.guest.city_id = id_city.id
            })

        },
        validateUser() {
            if (this.guest.cpf.length == 14) {
                this.isRegistered({ cpf: this.guest.cpf }).then((response) => {
                    if (response.data.status == false) {
                        this.$swal({
                            confirmButtonColor: '#16b1ff',
                            position: "center",
                            html: '<p>' + response.data.message + '</p>',
                            icon: "warning",
                            title: "Atenção",
                        });
                        this.error_message.cpf = 'CPF já cadastrado';
                    }
                });


            } else {
                this.error_message.cpf = '';
            }
        },

        validate() {
            let valid = true

            if (this.guest.name == '') {
                this.error_message.name = 'Campo obrigatório'
                valid = false
            }
            if (this.guest.cpf == '') {
                this.error_message.cpf = 'Campo obrigatório'
                valid = false
            }
            if (this.guest.address == '') {
                this.error_message.address = 'Campo obrigatório'
                valid = false
            }
            if (this.guest.number == '') {
                this.error_message.number = 'Campo obrigatório'
                valid = false
            }
            if (this.guest.neighborhood == '') {
                this.error_message.neighborhood = 'Campo obrigatório'
                valid = false
            }
            if (this.guest.country_id == '') {
                this.error_message.country_id = 'Campo obrigatório'
                valid = false
            }
            if (this.guest.zipcode == '') {
                this.error_message.zipcode = 'Campo obrigatório'
                valid = false
            }
            if (this.guest.state_id == '') {
                this.error_message.state_id = 'Campo obrigatório'
                valid = false
            }
            if (this.guest.city_id == '') {
                this.error_message.city_id = 'Campo obrigatório'
                valid = false
            }
            if (this.guest.email == '') {
                this.error_message.email = 'Campo obrigatório'
                valid = false
            }

            return valid
        },
        getCEP() {
            if (this.guest.zipcode.length != 10) {
                return false
            }
            const cepValido = /^[0-9]{8}$/;
            let cep = this.guest.zipcode.replace(/\D/g, "");
            const response = axios.get(
                `https://viacep.com.br/ws/${cep}/json/`
            ).then(response => {
                const data = response.data
                this.guest.address = data.logradouro
                this.guest.neighborhood = data.bairro
                this.guest.country_id = 31
                let id_state = this.state_items.find(state => state.acronym == data.uf)
                this.guest.state_id = id_state.id
                this.loadCitiesViaCep(data.localidade)

            }).catch(error => {
                this.guest.address = ''
                this.guest.neighborhood = ""
                this.guest.country_id = ""
                this.guest.state_id = ""
                this.guest.city_id = ""

            });
        },
        save() {
            if (!this.validate()) {
                return
            }

            this.loading = true
            this.register_guest(this.guest)
                .then(response => {
                    const data = response.data
                    if (data.status == true) {
                        this.$toast.success('Salvo com sucesso !')
                        this.guest = {
                            cpf: '',
                            name: '',
                            address: '',
                            number: '',
                            neighborhood: '',
                            country_id: '',
                            zipcode: '',
                            state_id: '',
                            city_id: '',
                            email: '',
                        }
                    } else {
                        this.$toast.error('Erro ao salvar !')
                    }

                })
                .catch()
                .finally(() => {
                    this.loading = false
                })
        },
        validateReserve() {
            if (this.reserve == '') {
                this.error_message.reserve = 'Campo obrigatório'
                return
            }
            const paylaod = {
                reserve_id: this.reserve
            }
            this.reserve = ''
            this.validate_reserve(paylaod).then(response => {
                if (response.data.status == true) {
                    this.showForm()
                    return

                } else {
                    this.$toast.error('Reserva inválida !')
                }
            })

        },
        showForm() {
            this.valid_reserve = true
        },

    },
}
</script>


<style scoped>
body {
    background-color: white;
}
</style>